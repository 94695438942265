import React from "react";
import Layout from "../domain/Layout";
import PageContent from "../domain/PageContent";
import { graphql } from "gatsby";
//import App from "../components/App";

export const query = graphql`
    query {
        allMarkdownRemark(
            sort: { fields: frontmatter___id, order: ASC }
            filter: { fileAbsolutePath: { regex: "/(1-100)/" } }
            skip: 0
            limit: 10
        ) {
            edges {
                node {
                    frontmatter {
                        images {
                            childImageSharp {
                                gatsbyImageData(
                                    placeholder: BLURRED
                                    layout: CONSTRAINED
                                )
                            }
                        }
                        alts
                        category
                        date
                        id
                        keywords
                        slug
                        source
                        subTitle
                        text
                        title
                        layout
                    }
                }
            }
        }
    }
`;

const IndexPage = ({
    data: {
        allMarkdownRemark: { edges },
    },
}) => {
    console.log(edges);
    return (
        <Layout>
            <PageContent pageData={edges} />
        </Layout>
    );
};

export default IndexPage;
